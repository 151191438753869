import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import BlogOverview from "../../../components/blog/overview/blogOverview.mdx";
export const query = graphql`
  {
    mdx(slug: { eq: "calorie-calculator-to-lose-weight/" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_introduction {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_introduction_alt
        image_introduction_desc
        image_introduction_credit_text
        image_introduction_credit_link
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        date(formatString: "MMMM D, YYYY")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const AppImage = makeShortcode("AppImage");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> A controlled food and
  especially calorie intake is the foundation for losing body fat or building
  muscle. Counting calories and macronutrients has proven to be particularly
  effective in this regard. Just a few years ago, athletes had to calculate and
  document everything by hand with calculators and paper. With the appearance of
  the first calorie counter apps, this effort has been reduced considerably.
  Still, it remains a chore to create almost identical food entries every day.
  ShapeGanic now introduces a feature that lets you do your daily tracking in a
  few seconds.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_introduction)} imageAlt={props.data.mdx.frontmatter.image_introduction_alt} imageDesc={props.data.mdx.frontmatter.image_introduction_desc} imageAuthor={props.data.mdx.frontmatter.image_introduction_credit_text} imageLink={props.data.mdx.frontmatter.image_introduction_credit_link} mdxType="Image" />
    <h2>{`This feature makes you more effective!`}</h2>
    <p>
  In fact, it was the constructive Google Play Store review of a user that
  suggested this feature. And since we are committed to taking our users'
  suggestions seriously, this idea was immediately put on the to-do list. And
  now we can show you below how you can easily copy meals from previous days
  with just a few clicks.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} mdxType="AppImage" />
    <p>
  If you have already tracked entries for past days, a button labeled "Copy
  entries" will appear in your dashboard. If you click this button, a new screen
  will appear where you can select a past day as a template from a horizontal
  list at the top. You can see this screen in the following figure.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} mdxType="AppImage" />
    <p>
  After you have selected a template day, all meal entries are displayed in the
  overview below. You now have the option to select or deselect all entries or
  individual meals and foods by default. When selecting the corresponding
  entries, you will immediately get a visual feedback regarding calories and
  macronutrients.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} mdxType="AppImage" />
    <p>
  The meals are sorted in the same way as you are used to in the Dashboard. When
  you have finished selecting the meals or foods, just scroll down and press the
  button labeled "Duplicate". Your entries will now be copied immediately and
  you'll be back to your dashboard. What can take several minutes in other apps
  and cause a lot of errors, you can now do in a few seconds with ShapeGanic.
    </p>
    <h2>{`How to achieve your goals...`}</h2>
    <p>
  In this article, you learned how to use ShapeGanic to take over entries from
  already tracked foods in no time. Focus on your diet, give it your all in
  training, and we'll take care of the rest!
    </p>
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      